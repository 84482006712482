import React from "react";

function ProductBox(props) {
  const { productImg, productName, date } = props;

  return (
    <div className="product-box">
      <div className="product-image-1">
        <img src={productImg} alt="product" />
      </div>
      <div className="product-text">
        {productName}
        <div className="date">Date: {date}</div>
      </div>

      <div className="Download-btn">
        <button className="down-btn">Download Quote</button>
        <div className="chat-history">Chat History</div>
      </div>
    </div>
  );
}

export default ProductBox;
