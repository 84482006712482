// D:\chatmro_frontend\chamro_website\src\Components\OverlayMessage.js
import React, { useState } from 'react';
import "../Overlay/overlay.css";
<link
  rel="stylesheet"
  href="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css"/>
    const OverlayMessage = ({ onPasswordSubmit }) => {
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState("");
    const anotherPassword = process.env.REACT_APP_TEMP_PASSWORD; // Replace with your static password
    const correctPassword = process.env.REACT_APP_CORRECT_PASSWORD; // Replace with your static password

    const handleSubmit = (e) => {
        e.preventDefault();
        if (password === correctPassword || password === anotherPassword) {
        onPasswordSubmit(email);
        } else {
        alert('Incorrect password, please try again.');
        }
    };
    const subject = encodeURIComponent('Request for password');
    const body = encodeURIComponent('Please provide me the password to access the chatmro.');
    const gmailUrl = `https://mail.google.com/mail/?view=cm&fs=1&to=balbir@raptorsupplies.com&su=${subject}&body=${body}`;
  
    return (
        <div className="overlay">
        <div className="overlay-box">
            <form onSubmit={handleSubmit}>
            <div>
                <label>Use your email and password to login..</label>
                <input
                className='form-control'
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder='Enter your email'
                required
                />
                <input
                className='form-control'
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Enter your password"
                required
                />
            </div>
            <button type="submit">Submit</button>
            </form>
            <div className="mt-3">
            <a href={gmailUrl} target="_blank" rel="noopener noreferrer" className="btn btn-link">
            Contact Us
          </a>
        </div>
        </div>
        </div>
    );
    };

    export default OverlayMessage;
