import React, { useState } from "react";
import ProductBox from "./ProductInfo";
import Search from "../../assets/icons/svg/search.svg";
import product_img1 from "../../assets/icons/svg/Image 1@2x.png";
import product_img2 from "../../assets/icons/svg/Image 4@2x.png";
import product_img3 from "../../assets/icons/svg/Image 5@2x.png";

const Searchbar = () => {
  const [searchInput, setSearchInput] = useState("");

  const handleChange = (e) => {
    e.preventDefault();
    setSearchInput(e.target.value);
  };

  const products = [
    {
      id: 1,
      productImg: product_img1,
      productName:
        "AKRO-MILS 30130YELLO Shelf Bin, 11-5/8 Inch Length, 6-5/8 Inch Width, 4 Inch Height, Yellow",
      date: "03-01-2023",
    },
    {
      id: 2,
      productImg: product_img2,
      productName:
        "BANJO FITTINGS EV200 Thread On/Off Electric Valve, 1-1/4 Sec Response Time, 2 Inch, Polypropylene",
      date: "03-01-2023",
    },
    {
      id: 3,
      productImg: product_img3,
      productName:
        "AIR SYSTEMS INTERNATIONAL SVF-10KW-8 Electric Heater, 10.5 KW, 8 Inch Duct",
      date: "03-01-2023",
    },
  ];

  return (
    <div className="history-box">
      <div className="search-container">
        <h2 className="col">Quote History</h2>
        <div className="search-bar">
          <input
            className="search-text"
            type="text"
            placeholder="Search here"
            onChange={handleChange}
            value={searchInput}
          />
          <div className="search-btn" onClick={handleChange}>
            <img src={Search} alt="search button icon" />
          </div>
        </div>
      </div>
      <div>
        {products.map((product) => (
          <ProductBox
            key={product.id}
            productImg={product.productImg}
            productName={product.productName}
            productDescription={product.productDescription}
            date={product.date}
          />
        ))}
      </div>
    </div>
  );
};

export default Searchbar;
