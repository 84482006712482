import React, { useState } from "react";
import Search from "../../assets/icons/svg/search.svg";

const Librarybox = () => {
  const [searchInput, setSearchInput] = useState("");
  const [checkboxes, setCheckboxes] = useState([
    {
      id: 1,
      label: "What is the return policy for Akro-mils 30130 series shelf bins?",
      isChecked: false,
    },
    {
      id: 2,
      label: "What is the lead time for Akro-mils 30130 series shelf bins?",
      isChecked: false,
    },
    {
      id: 3,
      label: "AKRO-MILS 30130 Series Shelf Bins, 128 Cu Inch Capacity",
      isChecked: false,
    },
    {
      id: 4,
      label:
        "what is the difference between akro-mils 30130 series shelf bins and other shelf bins",
      isChecked: false,
    },
  ]);

  const [yesterdayCheckboxes, setYesterdayCheckboxes] = useState([
    {
      id: 1,
      label:
        "what are the features of akro-mils 30130 series shelf bins that make them different from other shelf bins",
      isChecked: false,
    },
    {
      id: 2,
      label:
        "how do akro-mils 30130 series shelf bins compare to other brands of shelf bins in terms of durability and quality",
      isChecked: false,
    },
    {
      id: 3,
      label: "what are the dimensions of akro-mils 30130 series shelf bins",
      isChecked: false,
    },
    {
      id: 4,
      label:
        "what is the power consumption of air systems international svf-10kw-8 electric heater",
      isChecked: false,
    },
  ]);

  const [selectedCount, setSelectedCount] = useState(0);

  const handleChange = (e) => {
    e.preventDefault();
    setSearchInput(e.target.value);
  };

  const handleCheckboxChange = (id) => {
    const updatedCheckboxes = checkboxes.map((checkbox) =>
      checkbox.id === id
        ? { ...checkbox, isChecked: !checkbox.isChecked }
        : checkbox
    );
    setCheckboxes(updatedCheckboxes);
    const totalCount =
      updatedCheckboxes.filter((checkbox) => checkbox.isChecked).length +
      yesterdayCheckboxes.filter((checkbox) => checkbox.isChecked).length;
    setSelectedCount(totalCount);
  };

  const handleCheckboxChangeYesterday = (id) => {
    const updatedYesterdayCheckboxes = yesterdayCheckboxes.map((checkbox) =>
      checkbox.id === id
        ? { ...checkbox, isChecked: !checkbox.isChecked }
        : checkbox
    );
    setYesterdayCheckboxes(updatedYesterdayCheckboxes);
    const totalCount =
      checkboxes.filter((checkbox) => checkbox.isChecked).length +
      updatedYesterdayCheckboxes.filter((checkbox) => checkbox.isChecked)
        .length;
    setSelectedCount(totalCount);
  };

  return (
    <div className="library-container">
      <div className="search-container">
        <h2 className="col">Library</h2>
        <div className="search-bar">
          <input
            className="search-text"
            type="text"
            placeholder="Search here"
            onChange={handleChange}
            value={searchInput}
          />
          <div className="search-btn" onClick={handleChange}>
            <img src={Search} alt="search button icon" />
          </div>
        </div>
      </div>

      <div className="both-cont">
        <div className="tdy">
          <div className="today-container">Today</div>
          <hr />
          <div className="checklabel">
            {checkboxes.map((checkbox) => (
              <label className="label" key={checkbox.id}>
                <input
                  type="checkbox"
                  checked={checkbox.isChecked}
                  onChange={() => handleCheckboxChange(checkbox.id)}
                />
                {checkbox.label}
              </label>
            ))}
          </div>
        </div>

        <div className="tdy">
          <div className="today-container">Yesterday</div>
          <hr />
          <div className="checklabel">
            {yesterdayCheckboxes.map((checkbox) => (
              <label className="label" key={checkbox.id}>
                <input
                  type="checkbox"
                  checked={checkbox.isChecked}
                  onChange={() => handleCheckboxChangeYesterday(checkbox.id)}
                />
                {checkbox.label}
              </label>
            ))}
          </div>
        </div>
      </div>
      <div>Selected: {selectedCount}</div>
    </div>
  );
};

export default Librarybox;
