export const clearChats = async () => {
  localStorage.removeItem("conversation");
  localStorage.removeItem("sessionCreationTime");
  const url = process.env.REACT_APP_BACKEND_BASE_URL
  try {
    const response = await fetch( url + "/v2/clear-chat", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      // body: JSON.stringify({ "sessionId" : storedSessionId }), // Corrected key
    });

    if (response.ok) {
      console.log("Backend notified successfully");
    } else {
      console.log("Failed to notify backend:", response.statusText);
    }
  } catch (err) {
    console.log("Error notifying backend:", err);
  }
};
