import History from "../History";
import "../History/history.css";
function QuoteHistoryPage() {
  return (
    <div className="search-box">
      <History />
    </div>
  );
}

export default QuoteHistoryPage;
