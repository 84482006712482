export const saveChatToDatabase = async (chatData) => {
    const url = process.env.REACT_APP_BACKEND_BASE_URL
    try {
      const response = await fetch(url + '/chats/', { 
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(chatData),
      });
      if (!response.ok) {
        throw new Error('Failed to save chat data');
      }
      return await response.json();
    } catch (error) {
      console.error('Error saving chat to database:', error);
    }
  };
  