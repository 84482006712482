import Chat from "../Chat";
import Input from "../Input";
import { useState, useEffect, useRef } from "react";
import { v4 as uuidv4 } from "uuid";
import "../Chat/chat.css";
import "../Input/input.css";
import { fetchAnswerFromQuery } from "../../services/Chat";
import OverlayMessage from "../Overlay";
import useLinksInNewTab from "../newTabLink";
import { saveChatToDatabase } from "../../services/sendToDb"
import { useLocation } from "react-router-dom";

function MainPage({ developerMode }) {
  const [prompt, setPrompt] = useState("");
  const [conversation, setConversation] = useState([]);
  const [isFetchingAnswer, setIsFetchingAnswer] = useState(false);
  const [initialConversationLoaded, setInitialConversationLoaded] = useState(false);
  const [sessionId, setSessionId] = useState("");
  const [email, setEmail] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);
  const [selectedModel, setSelectedModel] = useState("openai_ch");
  const sessionTimeoutSeconds = 4 * 60 * 60 * 1000; // 4 hours in milliseconds
  const location = useLocation();
  const isV2 = location.pathname.startsWith("/v2");  

  
  const inputRef = useRef(null);
  useLinksInNewTab(conversation);
  // const storedEmail = localStorage.getItem("email");
  // if (storedEmail) setEmail(storedEmail);
  
  let chatCounter = useRef(1);
    
    useEffect(() => {
      if (isV2) {
        const v2Handled = localStorage.getItem("v2Handled");
        if (!v2Handled) {
          const storedEmail = localStorage.getItem("email");
          localStorage.clear();
          if (storedEmail) {
            localStorage.setItem("email", storedEmail);
          }
          localStorage.setItem("v1handled", "false")
          localStorage.setItem("v2Handled", "true");
        }
      }
    }, [isV2]);
    
  useEffect(() => {
    // Check session on page load/refresh
    checkSessionExpiration();
    // Set interval to check session every minute
    const sessionCheckInterval = setInterval(checkSessionExpiration, 60000); // check every minute

    return () => clearInterval(sessionCheckInterval); // Clean up interval on component unmount
  }, []);

  const checkSessionExpiration = () => {
    const sessionCreationTime = localStorage.getItem("sessionCreationTime");
    const now = Date.now();

    if (!sessionCreationTime || now - parseInt(sessionCreationTime, 10) > sessionTimeoutSeconds) {
      handleSessionExpiration();
    } else {
      // const storedEmail = localStorage.getItem("email");

      // if (storedEmail && !email) {
      //     setEmail(storedEmail);
      // }
      const storedSessionId = localStorage.getItem("sessionId");
      if (storedSessionId) setSessionId(storedSessionId);
      
      const storedConversation = localStorage.getItem("conversation");
      if (storedConversation) {
        setConversation(JSON.parse(storedConversation).map(chat => ({ ...chat, isAnswerFromThisChat: false })));
      }
      setShowOverlay(false);
    }
    setInitialConversationLoaded(true);
  };

  const handleSessionExpiration = () => {
    const storedemail = localStorage.getItem("email")
    // localStorage.clear();
    console.log('Session expired');
    console.log(storedemail)
    localStorage.setItem('email',storedemail)
    setShowOverlay(true);
    setConversation([]);
    // displayAlert();
    chatCounter.current = 1; 
  };


  const displayAlert = () => {
    setShowAlert(true);
    setTimeout(() => setShowAlert(false), 10000);
  };

  const getAnswer = async () => {
    setIsFetchingAnswer(true);
    try {
      const response = await fetchAnswerFromQuery({ query: prompt, email: localStorage.getItem('email'), sessionId, conversation, model: selectedModel, version:  isV2 ? "v2" : "v1" }, isV2 ? "v2" : "");
      const newConversation = [
        ...conversation.map(chat => ({ ...chat, isAnswerFromThisChat: false })),
        {
          id: Date.now().toString(),
          email:localStorage.getItem('email'),
          user:'User',
          prompt,
          sessionId,
          answer: response[1],
          backendAnswer: response[0],
          isAnswerFromThisChat: true,
          version:  isV2 ? "v2" : "v1",
        },
      ];      
      setConversation(newConversation);
      localStorage.setItem("conversation", JSON.stringify(newConversation));
      
      setIsFetchingAnswer(false);
      await saveChatToDatabase(newConversation[newConversation.length - 1]);
      
    } catch (err) {
      console.error(err);
    } finally {
      setIsFetchingAnswer(false);
      setPrompt("");
      inputRef.current?.focus();
    }
  };

  const handlePasswordSubmit = (email) => {
    const newSessionId = uuidv4();
    const currentTime = Date.now();
    localStorage.setItem("sessionId", newSessionId);
    localStorage.setItem("sessionCreationTime", currentTime.toString());
    localStorage.setItem("email", email); 
    setEmail(email);
    setSessionId(newSessionId);
    displayAlert();
    window.location.reload();
    setShowOverlay(false);
    inputRef.current?.focus();
  };

  return (
    <div className="inner-box">
      <div className="model-dropdown">
        <select value={selectedModel} onChange={(e) => setSelectedModel(e.target.value)}>
          <option value="openai_ch">GPT-4o</option>
          <option value="openai_mini">GPT-4omini</option>
          <option value="chatgpt">GPT-3.5</option>
          <option value="gemma2-9b-it">gemma2-9b-it</option>
          <option value="llama3-70b-8192">llama3-70b-8192</option>
          <option value="llama3-8b-8192">llama3-8b-8192</option>
          <option value="llama3-groq-70b-8192-tool-use-preview">llama3-groq-70b-8192-tool-use-preview</option>
          <option value="mixtral-8x7b-32768">mixtral-8x7b-32768</option>          
        </select>
      </div>      
            
      {/* <RecentChats 
        email={email} 
        setSessionId={setSessionId} 
        setConversation={setConversation} 
      /> */}
      <Chat
        conversation={conversation}
        isFetchingAnswer={isFetchingAnswer}
        currentPrompt={prompt}
        developerMode={developerMode}
      />
      {initialConversationLoaded && (
        <Input
        ref={inputRef}
        prompt={prompt}
        isFetchingAnswer={isFetchingAnswer}
        onChange={(e) => setPrompt(e.target.value)}
        handleSubmit={() => {
          if (prompt.length > 0) getAnswer();
        }}
        />
      )}
      {showAlert && <div className="alert-box">New session started</div>}
      {showOverlay && <OverlayMessage onPasswordSubmit={handlePasswordSubmit} />}
    </div>
  );
}

export default MainPage;
