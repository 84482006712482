import axios from "axios";

axios.defaults.baseURL = process.env.REACT_APP_BACKEND_BASE_URL;

export const fetchAnswerFromQuery = (payload = {}, version = "") => {
  const endpoint = version === "v2" ? "/v2/user_chat" : "/user_chat";
  
  return axios
    .post(endpoint, payload)
    .then((res) => res.data)
    .catch((err) => err);
};
