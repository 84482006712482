import { useEffect } from 'react';

function useLinksInNewTab(dependency) {
  useEffect(() => {
    const links = document.querySelectorAll('a');
    links.forEach(link => {
      link.setAttribute('target', '_blank');
    });
  }, [dependency]); // Adding a dependency to rerun the effect when dependency changes
}

export default useLinksInNewTab;
