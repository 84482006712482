import "./App.css";
import { Navigate, useLocation } from "react-router-dom";
import Sidebar from "./Components/Sidebar";
import "./Components/Chat/chat.css";
import MainPage from "./Components/Pages/MainPage";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import QuoteHistoryPage from "./Components/Pages/QuoteHistoryPage";
import LibraryPage from "./Components/Pages/LibraryPage";
import PageNotFound from "./Components/Pages/PageNotFound";
import RouteContainer from "./Components/Pages/RouteContainer";
import {ServerRoute,ProcesRoute } from "./Components/Pages/ServerRoute";

function App() {
  // Perform api call for user details
  // BASE_URL+ {endpoint}
  // get call -> fetch data
  // post call -> submit data
  // signnup api -> use details ko store kroge => then usko screen pe dikhaoge
  // Moreover, login ke baad you need to persist the user details -> SKIP
  return (
    <Routes>
      <Route path="/home" element={<RouteContainer Component={MainPage} />} />
      <Route
        path="/index.html"
        element={<RouteContainer Component={MainPage} />}
      />
      <Route exact path="/" element={<RouteContainer Component={MainPage} />} />
      <Route
        exact
        path="/quote_history"
        element={<RouteContainer Component={QuoteHistoryPage} />}
      />
      <Route
        exact
        path="/library"
        element={<RouteContainer Component={LibraryPage} />}
      />
      <Route exact path="*" element={<RouteContainer Component={MainPage} />} />
      <Route exact path="/backend/*" element={<ServerRoute />} />
      <Route exact path="/process/*" element={<ProcesRoute />} />
      <Route exact path="/v2/" element={<RouteContainer Component={MainPage} version="v2" />} />
    </Routes>
  );
}

export default App;
