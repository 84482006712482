import React, { useEffect, useState } from "react";
import { fetchRecentChats } from "../../services/fetchRecentChats";
import "../RecentChats/recentChat.css";
import dayjs from "dayjs";
import { useLocation } from "react-router-dom";

function RecentChats({ email, setSessionId, setConversation }) {
    const [recentChats, setRecentChats] = useState([]);
    const [selectedSessionId, setSelectedSessionId] = useState(null);
    const [loading, setLoading] = useState(false);
    const location = useLocation();
    useEffect(() => {
        const isV2 = location.pathname.includes("/v2");
        const version = isV2 ? "v2" : "v1";
        setLoading(true);
        fetchRecentChats(email, version)
            .then(chats => {
                setRecentChats(chats);
                setLoading(false);
            })
            .catch(err => {
                console.error("Failed to fetch recent chats", err);
                setLoading(false);
            });
    }, [email]);

    const handleChatClick = (sessionId) => {
        const selectedChatDetails = recentChats[sessionId];
        if (selectedChatDetails && selectedChatDetails.length > 0) {
            // Sort the chat messages by `id` in ascending order (oldest first)
            const sortedChatDetails = [...selectedChatDetails].sort((a, b) => a.id - b.id);

            const sessionDetails = sortedChatDetails[0]; // The first message in the sorted list

            setSelectedSessionId(sessionId);
            setSessionId(sessionDetails.session_id);
            setConversation(sortedChatDetails);  // Set the sorted conversation

            localStorage.setItem("sessionId", sessionDetails.session_id);
            localStorage.setItem("conversation", JSON.stringify(sortedChatDetails));

            window.location.reload();
        }
    };

    const truncateText = (text, maxLength) => {
        return text.length > maxLength ? text.substring(0, maxLength) + "..." : text;
    };

    const today = dayjs().startOf('day');
    const todayChats = [];
    const previousChats = [];

    Object.keys(recentChats).forEach(sessionId => {
        const chatDate = dayjs(recentChats[sessionId][0]?.created_at);
        if (chatDate.isAfter(today)) {
            todayChats.push({ sessionId, chat: recentChats[sessionId] });
        } else {
            previousChats.push({ sessionId, chat: recentChats[sessionId] });
        }
    });

    if (loading) {
        return (
            <div className="loader">                
            </div>
        );
    }

    if (Object.keys(recentChats).length === 0) {
        return <div>No chats present</div>;
    }

    return (
        <div className="recent-chats">
            <h5>Recent Chats</h5>
            {todayChats.length > 0 && (
                <>
                    <p className="today">Today</p>
                    <ul>
                        {todayChats.map(({ sessionId }) => (
                            <li key={sessionId} onClick={() => handleChatClick(sessionId)}
                            className={sessionId === selectedSessionId ? "active" : ""}>
                                {truncateText(recentChats[sessionId][0]?.prompt || "Untitled Chat", 20)}
                            </li>
                        ))}
                    </ul>
                </>
            )}
            {previousChats.length > 0 && (
                <>
                    <p className="previous">Previous Chats</p>                    
                    <ul>
                        {previousChats.map(({ sessionId }) => (
                            <li key={sessionId} onClick={() => handleChatClick(sessionId)}
                                className={sessionId === selectedSessionId ? "active" : ""}>
                                {truncateText(recentChats[sessionId][0]?.prompt || "Untitled Chat", 20)}
                            </li>
                        ))}
                    </ul>
                </>
            )}
        </div>
    );
}

export default RecentChats;
