export async function fetchRecentChats(email, version) {
    const url = process.env.REACT_APP_BACKEND_BASE_URL
    const versionParam = version === "v2" ? "v2" : "v1"; // Default to "v1" if version is not "v2"

    console.log('Fetching recent chat ')
    try {
        const response = await fetch(url + `/recent-chats?email=${encodeURIComponent(email)}&version=${versionParam}`);
        if (!response.ok) {
            throw new Error("Failed to fetch recent chats");
        }
      console.log('Fetched recent chat ')
      const data = await response.json();
      console.log(data)
      return data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
  