import React, { forwardRef } from "react";
import Submit from "../../assets/icons/svg/submit.svg";

const Index = forwardRef(({
  prompt,
  placeholder = "Ask Anything...",
  onChange = () => {},
  styles,
  handleSubmit = () => {},
  isFetchingAnswer,
}, ref) => {
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  };

  return (
    <div className="search-line">
      <div className="text">
        <input
          ref={ref}
          className="small-box"
          type="text"
          placeholder={placeholder}
          style={styles}
          value={isFetchingAnswer ? "" : prompt}
          onChange={onChange}
          onKeyDown={handleKeyDown}
          disabled={isFetchingAnswer}
        />
        <div className="submit-btn" onClick={handleSubmit}>
          <img src={Submit} alt="Submit button icon" />
        </div>
      </div>
      <label className="mistake-label">ChatMRO can make mistakes. Check important info.</label>
    </div>
  );
});

export default Index;
