import Library from "../Library";
import "../Library/library.css";

function LibraryPage() {
  return (
    <div className="library-box">
      <Library />
    </div>
  );
}
export default LibraryPage;
