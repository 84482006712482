import React, { useState  } from 'react';
import Sidebar from "../Sidebar";

const RouteContainer = ({ Component }) => {  
  const [developerMode, setDeveloperMode] = useState(false);


  const toggleDeveloperMode = () => {
    setDeveloperMode(!developerMode);
  };
  

  return (
    <div className="outer-box">
      <div className="navigationSidebar">
        <Sidebar
          developerMode={developerMode}
          toggleDeveloperMode={toggleDeveloperMode}
        />
      </div>
      <Component developerMode={developerMode} />
    </div>
  );
};

export default RouteContainer;
